import styled from '@emotion/styled'
import parse from 'html-react-parser'
import i18n from 'i18next'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { SalesformId, SalesService } from '../api'
import {
  Button,
  ContentContainer,
  Heading,
  PageContainer,
  PageContainerOuter,
  Paragraph,
} from '../components'
import { PageFrame, PageParams } from '../components/organisms/pageFrame'
import { SalesForm } from '../components/organisms/salesForm'
import {
  SalesFormData,
  SALESFORM_ERROR,
  SALESFORM_SENT,
} from '../components/organisms/salesForm'
import { mq } from './../utility/breakpoint'

const SalesformPage = (params: PageParams) => {
  const {
    pageContext: { lang },
  } = params

  const { t } = useTranslation('salesform')

  const [loaded, setLoaded] = React.useState(false)
  const [salesFormStatus, setSalesFormStatus] = React.useState<
    typeof SALESFORM_ERROR | typeof SALESFORM_SENT
  >()

  const [selectedSalesformId, setSelectedSalesformId] = React.useState<
    SalesformId
  >()

  useEffect(() => {
    i18n.changeLanguage(lang)
    setLoaded(true)
  }, [])

  /*  React.useEffect(() => {
    const d = {
    }
    SalesService.sendForm(d)
  }, []) */

  const sendForm = (data: any) => {
    const tags = SalesService.getTags({ ...data })

    SalesService.sendForm(data, tags).then(({ status, error }) => {
      setSalesFormStatus(status !== 204 ? SALESFORM_ERROR : SALESFORM_SENT)
    })
  }

  return (
    <PageFrame
      {...params}
      hideMenu={true}
      pageName="salesform-7hs5Hz3as"
      perectScroll={false}
    >
      <PageContainerOuter>
        <PageContainer>
          <SalesForm
            onSubmit={data => sendForm(data)}
            status={salesFormStatus}
            onReset={() => setSalesFormStatus(undefined)}
            lang={lang}
          />
        </PageContainer>
      </PageContainerOuter>
    </PageFrame>
  )
}

const SalesformSelectContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  '& > button:not(:last-of-type)': { marginRight: '1rem' },
  margin: '1rem 0',
})

const OuterContainer = styled.div({
  marginTop: '60px',
  [`${mq[3]}`]: {
    marginTop: '0px',
  },
})

export default SalesformPage
