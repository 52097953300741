import styled from '@emotion/styled'
import { log } from 'console'
import { Dayjs, Form, TimeUtil } from 'cuenect-web-core'
import dayjs from 'dayjs'
import timezonePlugin from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import parse from 'html-react-parser'
import humanizeDuration from 'humanize-duration'
import i18n from 'i18next'
import React, { MutableRefObject } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import ReactTooltip from 'react-tooltip'
import {
  Button,
  Checkbox,
  CheckboxGroup,
  Heading,
  Input,
  Paragraph,
  Radiobutton,
  RadiobuttonGroup,
  Select,
  SieClose,
  SiePlus,
  SieArrowBack,
  Textarea,
} from '../..'
import { SalesUser } from '../../../api'
import { salesFormConfig } from '../../../config'
import { IanaTimezones } from '../../../utility'
import { mq } from '../../../utility/breakpoint'
import { ErrorBar, TooltipIcon } from '../../atoms'
import {
  DatePicker,
  DatePickerProps,
  DateRangePicker,
  DateRangePickerProps,
} from '../../molecules/datePicker'
import { CountryList } from './countryList'
import { Showroom, ShowroomList } from './showroomList'

import { MeetingLanguages } from './meetingLanguages'
import { flattenDateRange } from './utils'

dayjs.extend(utc)
dayjs.extend(timezonePlugin)

TimeUtil.extend(utc)
TimeUtil.extend(timezonePlugin)

export const SALESFORM_SENT = 'sent'
export const SALESFORM_ERROR = 'error'

export interface SalesFormProps {
  onSubmit?(data: SalesFormData): void
}

export interface SalesFormData {
  version: string
  businessUnit: string
  country: string
  meetinglanguage: string
  emaillanguage: string
  note: string
  date: string
  start: string | null
  end: string | null
  time: string
  timezone: string
  email: string
  gender: string
  firstname: string
  lastname: string
  expertEmail: string
  expertFirstname: string
  expertLastname: string
  expertStart: string | string[] | null
  expertEnd: string | string[] | null
  host: SalesUser
  hostEmail: string
  hostFirstname: string
  hostLastname: string
  resources: SalesUser[]
  showroom: number[]
}

export interface SalesFormProps {
  onSubmit?(data: SalesFormData): void
  onComplete?(success: boolean, error?: string): void
  onReset?: Function
  status?: typeof SALESFORM_SENT | typeof SALESFORM_ERROR
  error?: string
  lang: string
}

export const SalesForm: React.FC<SalesFormProps> = props => {
  const { register, trigger, handleSubmit, errors, getValues } = useForm<
    SalesFormData
  >({ mode: 'onSubmit' })

  const [selectedTimezone, setSelectedTimezone] = React.useState<string>(
    TimeUtil.getUsersTimezone() || 'Europe/London'
  )

  const [formSubmitted, setFormSubmitted] = React.useState(false)

  const { t } = useTranslation('salesform')

  const [sending, setSending] = React.useState(false)
  const [clients, setClients] = React.useState([''])

  const MIN_DATE = TimeUtil.getUtc('2021-06-21 00:00:00')
  const MAX_DATE = TimeUtil.getUtc('2021-06-25 00:00:00')

  const START_TIME = TimeUtil.getUtc('2021-06-21 10:00:00')
  const END_TIME = TimeUtil.getUtc('2021-06-21 12:00:00')

  const RANGE_START_VIRTUAL = '2021-11-30T02:00Z'
  const RANGE_END_VIRTUAL = '2021-12-01T21:00Z'

  const getRange = () => {
    return [RANGE_START_VIRTUAL, RANGE_END_VIRTUAL] as const
  }

  const [minDate, setMinDate] = React.useState(MIN_DATE)
  const [maxDate, setMaxDate] = React.useState(MAX_DATE)

  const firstPossibleDate = flattenDateRange(salesFormConfig.datesRange)
    .firstPossibleDate
  const [dateFlatRanges, setDateFlatRange] = React.useState(
    flattenDateRange(salesFormConfig.datesRange).dates
  )

  const [date, setDate] = React.useState(firstPossibleDate)

  if (!firstPossibleDate) {
    return (
      <>
        <Heading type="h2">{t('deactive.header')}</Heading>
        <Paragraph>{parse(t('deactive.copy'))}</Paragraph>
      </>
    )
  }

  // GET FIRST POSSIBLE HOUR
  const currentTimeAdded = TimeUtil.get(
    new Date(
      Math.ceil(
        TimeUtil.getNow().add(20, 'minutes').toDate().getTime() / 900000
      ) * 900000
    ).toString()
  )

  const firstPossibleTime = date?.isSame(TimeUtil.getNow(), 'day')
    ? currentTimeAdded
    : firstPossibleDate.hour(0).minute(0)

  const firstPossibleTimeStart = firstPossibleTime.isBefore(
    firstPossibleTime.hour(12).minute(0).second(0)
  )
    ? firstPossibleTime.hour(12).minute(0).second(0)
    : firstPossibleTime

  const [startDate, setStartDate] = React.useState(firstPossibleTimeStart)

  const [endDate, setEndDate] = React.useState(
    firstPossibleTimeStart.add(2, 'hours')
  )

  const [startDateHosts, setStartDateHosts] = React.useState<Dayjs[]>([
    firstPossibleTimeStart,
  ])
  const [endDateHosts, setEndDateHosts] = React.useState<Dayjs[]>([
    firstPossibleTimeStart.add(2, 'hours'),
  ])

  const [dateTimeRange, setDateTimeRange] = React.useState([
    firstPossibleTime,
    firstPossibleTime.hour(23).minute(59),
  ])

  const [experts, setExperts] = React.useState([[startDate, endDate]])

  const [duration, setDuration] = React.useState(0)

  const changeDate = (d: Dayjs) => {
    // UPDATE TIMES
    const nStartDate = startDate.set('date', d.date()).set('month', d.month())
    const nEndDate = endDate.set('date', d.date()).set('month', d.month())

    setStartDate(nStartDate)
    setEndDate(nEndDate)
    // GET FIRST POSSIBLE HOUR
    const cTimeAdded = TimeUtil.get(
      new Date(
        Math.ceil(
          TimeUtil.getNow().add(20, 'minutes').toDate().getTime() / 900000
        ) * 900000
      ).toString()
    )

    const fPossibleTime = nStartDate
      .set('date', d.date() + 1)
      .isSame(TimeUtil.getNow(), 'day')
      ? cTimeAdded
      : nStartDate.hour(0).minute(0)

    setDateTimeRange([fPossibleTime, fPossibleTime.hour(23).minute(59)])
  }

  const changeTime = (time: Dayjs[], index?: number | undefined) => {
    if (index === undefined) {
      setStartDate(time[0])
      setEndDate(time[1])
    } else {
      const sd = startDateHosts

      sd[index] = time[0]
      setStartDateHosts(sd)

      const ed = endDateHosts
      ed[index] = time[1]
      setEndDateHosts(ed)
    }
  }

  const timezoneSelectData = () =>
    IanaTimezones.map(({ name }, index) => (
      <option value={name} key={index}>
        {name}
      </option>
    ))

  const meetingLanguageSelectData = () => (
    <>
      <option />
      <option value="en">English</option>
      <option value="de">German</option>
    </>
  )

  const emilLanguageSelectData = () => (
    <>
      <option />
      <option value="en">English</option>
      <option value="de">German</option>
    </>
  )

  const businessUnitData = () => (
    <>
      <option />
      <option value="ADV">ADV</option>
      <option value="MC">MC</option>
      <option value="T">T</option>
      <option value="SW">SW</option>
      <option value="Training">
        {i18n.language === 'de'
          ? `Testtermin für Prozess-Training`
          : `Testappointment for Process-Training`}
      </option>
    </>
  )

  const countrySelectData = () => {
    const lang = i18n.language === 'de' ? 'de' : 'en'

    return [
      {
        name: { en: '', de: '', it: '' },
        code: '',
        value: 'string',
      },
      ...CountryList,
    ]
      .sort((a, b) => a.name[lang].localeCompare(b.name[lang]))
      .map(({ value, name, code }) => ({
        name: name[lang],
        value,
      }))
  }

  const usePrevious = (val: any) => {
    const ref = React.useRef()
    React.useEffect(() => {
      ref.current = val
    })

    return ref.current
  }
  const prevTimezone = usePrevious(selectedTimezone) || selectedTimezone

  const shiftTimezone = (
    shiftDate: Dayjs,
    timezone: string,
    pTimezone = 'Europe/London'
  ): Dayjs => {
    return shiftDate.add(
      TimeUtil.getUtcOffsetForTz(pTimezone) * -1 +
        TimeUtil.getUtcOffsetForTz(timezone),
      'minutes'
    )
  }

  const shiftTimezoneSend = (
    shiftDate: Dayjs,
    timezone: string,
    pTimezone = 'Europe/London'
  ): Dayjs => TimeUtil.convertToTimeZone(shiftDate, pTimezone).utc()

  React.useEffect(() => {
    const shiftedStartTime: Dayjs = shiftTimezone(
      startDate,
      selectedTimezone,
      prevTimezone
    )
    const shiftedEndTime: Dayjs = shiftTimezone(
      endDate,
      selectedTimezone,
      prevTimezone
    )

    const _date = TimeUtil.get(
      new Date(
        shiftedStartTime.year(),
        shiftedStartTime.month(),
        shiftedStartTime.date()
      ).toString()
    )

    // CHECK IS NOT BEFORE FIRTS POSSIBLE DATE
    if (_date.isBefore(dateFlatRanges[0])) {
      setDate(dateFlatRanges[0])
    } else {
      setDate(_date)
    }

    //  setDate(_date)

    const shifteddMinDate: Dayjs = shiftTimezone(
      MIN_DATE.utc(),
      selectedTimezone
    )
    setMinDate(shifteddMinDate)

    const shifteddMaxDate: Dayjs = shiftTimezone(
      MAX_DATE.utc(),
      selectedTimezone
    )
    setMaxDate(shifteddMaxDate)

    setStartDate(shiftedStartTime)
    setEndDate(shiftedEndTime)
    validateTimes([shiftedStartTime, shiftedEndTime])
  }, [selectedTimezone])

  const validateTimes = (times: Dayjs[]) => {
    /*setStartDate(shiftedStartTime)
    setEndDate(shiftedEndTime)*/
  }
  const shiftSubmit = (d: string) => {
    if (d === '') {
      return null
    }

    //return dayjs(TimeUtil.getUtc(d).format('YYYY-MM-DD HH:mm')).utc().format()
    return dayjs.tz(d.slice(0, 19), selectedTimezone).utc().format()

    return TimeUtil.getUtc(d.slice(0, 19)).utc().format() // REMOVE TIMEZONE

    return shiftTimezoneSend(
      TimeUtil.getUtc(d),
      'Europe/London',
      selectedTimezone
    ).format()
  }

  const shiftSubmitTimes = (data: SalesFormData) => {
    const shiftedData = { ...data }
    shiftedData.start = shiftSubmit(shiftedData.start)
    shiftedData.end = shiftSubmit(shiftedData.end)

    if (
      shiftedData.expertStart &&
      typeof shiftedData.expertStart !== 'string'
    ) {
      shiftedData.expertStart = shiftedData.expertStart.map(
        expertStart => shiftSubmit(expertStart) as string
      )
    }
    if (shiftedData.expertEnd && typeof shiftedData.expertEnd !== 'string') {
      shiftedData.expertEnd = shiftedData.expertEnd.map(
        expertEnd => shiftSubmit(expertEnd) as string
      )
    }

    return shiftedData
  }

  console.log({ startDate, endDate })

  return (
    <SalesFormOuter>
      <Form
        onSubmit={handleSubmit(data => {
          const sendData = shiftSubmitTimes(data)
          console.log('sendData', sendData)

          setSending(true)
          props.onSubmit && props.onSubmit(sendData)
        })}
      >
        {!props.status && (
          <>
            <Heading type="h2">{t('intro.header')}</Heading>
            <Paragraph>{parse(t('intro.copy'))}</Paragraph>

            <SalesFormContent>
              {/* category */}
              <FormField>
                <Heading type="h5">
                  {t('category.label')}
                  <TooltipIcon value={t('category.copy')} />
                </Heading>
                <FormRow>
                  {wrapInput(
                    'businessUnit',
                    Select,
                    {
                      placeholder: t('fields.businessUnit'),
                      defaultValue: 'de',
                      name: 'businessUnit',
                      ref: register({ required: true }),
                      children: businessUnitData(),
                      error: errors[`businessUnit`] ? t('fields.required') : '',
                    },
                    1
                  )}
                </FormRow>

                {/* country */}
                <FormRow>
                  {wrapInput(
                    'country',
                    Select,
                    {
                      search: true,
                      placeholder: t('fields.country'),
                      name: 'country',
                      ref: register({ required: true }),
                      error: errors[`country`] ? 'Invalid country' : '',
                      options: countrySelectData(),
                      trigger: () => trigger('country'),
                    },
                    1
                  )}
                </FormRow>
              </FormField>

              {/* Languages */}
              <FormField>
                <Heading type="h5">
                  {t('languages.label')}{' '}
                  <TooltipIcon value={t('languages.copy')} />
                </Heading>

                <FormRow>
                  {wrapInput(
                    'meetinglanguage',
                    Select,
                    {
                      name: 'meetinglanguage',
                      placeholder: t('fields.meetinglanguage'),
                      defaultValue: i18n.language,
                      ref: register({ required: true }),
                      children: meetingLanguageSelectData(),
                      error: errors[`meetinglanguage`]
                        ? t('fields.required')
                        : '',
                    },
                    1
                  )}
                </FormRow>

                <FormRow>
                  {wrapInput(
                    'emaillanguage',
                    Select,
                    {
                      name: 'emaillanguage',
                      placeholder: t('fields.emaillanguage'),
                      defaultValue: i18n.language,
                      ref: register({ required: true }),
                      children: emilLanguageSelectData(),
                      error: errors[`emaillanguage`]
                        ? t('fields.required')
                        : '',
                    },
                    1
                  )}
                </FormRow>
                {/* time zone */}
                <FormRow>
                  {wrapInput(
                    'timezone',
                    Select,
                    {
                      name: 'timezone',
                      ref: register({ required: true }),
                      placeholder: t('fields.timezone'),
                      defaultValue: 'Europe/Berlin',
                      value: selectedTimezone,
                      children: timezoneSelectData(),
                      onChange: (e: React.FormEvent<HTMLSelectElement>) =>
                        setSelectedTimezone(e.currentTarget.value),
                    },
                    1
                  )}
                </FormRow>
              </FormField>

              {/* DATEPICKER DATEPICKER */}
              <FormField>
                <Heading type="h5">
                  {t('appointment.label')}
                  <TooltipIcon value={t('appointment.copy')} />
                </Heading>

                <FormRow>
                  {wrapInput(
                    'date',
                    DatePicker,
                    {
                      name: 'date',
                      label: t('fields.date'),
                      dateRanges: dateFlatRanges,
                      filterDate: date => {
                        const day = date.getDay()

                        return day !== 0 && day !== 6
                      },

                      value: date,
                      dateFormat: 'd. MMMM y',
                      showTime: false,

                      ref: register({ required: true }),
                      onChange: e => changeDate(e),
                    },
                    1
                  )}
                  <MobileBreak />

                  {/* TIMEPICKER 1 */}
                  {wrapInput(
                    'time',
                    DateRangePicker,
                    {
                      name: ['start', 'end'],
                      label: [t('fields.start'), t('fields.end')],
                      value: [startDate, endDate],
                      range: [
                        shiftTimezone(
                          TimeUtil.getUtc(getRange(props.salesformId)[0]),
                          selectedTimezone
                        ),
                        shiftTimezone(
                          TimeUtil.getUtc(getRange(props.salesformId)[1]),
                          selectedTimezone
                        ),
                      ],
                      register,
                      onChange: (time: Dayjs[]) => changeTime(time),
                    },
                    2
                  )}
                  {startDate.isBefore(endDate) && (
                    <div className="elem" style={{ flex: 0.5 }}>
                      {humanizeDuration(endDate.diff(startDate), {
                        language: i18n.language,
                      })}
                    </div>
                  )}
                </FormRow>
              </FormField>

              <FormField>
                <Heading type="h5">
                  {t('clients.label')}
                  <TooltipIcon value={t('clients.copy')} />
                </Heading>
                {clients.map((val, i) => (
                  <FormRow key={i}>
                    {wrapInput(
                      'email',
                      Input,
                      {
                        placeholder: t('fields.email'),
                        name: `email[${i}]`,
                        ref: register({
                          required: true,
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: t('fields.required'),
                          },
                        }),
                        error:
                          errors[`email`] && errors[`email`][i]
                            ? t('fields.required')
                            : '',
                      },
                      1
                    )}
                    <MobileBreak />
                    {wrapInput(
                      'gender',
                      RadiobuttonGroup,
                      {
                        children: (
                          <>
                            <Radiobutton
                              value="Ms."
                              name={`gender[${i}]`}
                              ref={register({ required: true })}
                            >
                              {t('fields.mrs')}
                            </Radiobutton>
                            <Radiobutton
                              value="Mr."
                              name={`gender[${i}]`}
                              ref={register({ required: true })}
                            >
                              {t('fields.mr')}
                            </Radiobutton>
                          </>
                        ),
                        error:
                          errors[`gender`] && errors[`gender`][i]
                            ? t('fields.required')
                            : '',
                      },
                      1
                    )}
                    <MobileBreak />
                    {wrapInput(
                      'firstname',
                      Input,
                      {
                        placeholder: t('fields.firstname'),
                        name: `firstname[${i}]`,
                        ref: register({
                          required: true,
                        }),
                        error:
                          errors[`firstname`] && errors[`firstname`][i]
                            ? t('fields.required')
                            : '',
                      },
                      1
                    )}
                    <MobileBreak />
                    {wrapInput(
                      'lastname',
                      Input,
                      {
                        placeholder: t('fields.lastname'),
                        name: `lastname[${i}]`,
                        ref: register({
                          required: true,
                        }),
                        error:
                          errors[`lastname`] && errors[`lastname`][i]
                            ? t('fields.required')
                            : '',
                      },
                      1
                    )}
                    {i ? (
                      <RemoveBtn
                        onClick={() => {
                          setClients(
                            clients
                              .slice(0, i)
                              .concat(clients.slice(i + 1, clients.length))
                          )
                        }}
                      >
                        <SieClose />
                      </RemoveBtn>
                    ) : (
                      ''
                    )}
                  </FormRow>
                ))}
                <Button
                  tertiary
                  plain
                  small
                  icon={SiePlus}
                  onClick={() => {
                    setClients([...clients, ''])
                  }}
                >
                  {t('fields.add')}
                </Button>
              </FormField>
              <FormField>
                <Heading type="h5">
                  {t('host.label')}
                  <TooltipIcon value={t('host.copy')} />
                </Heading>
                <FormRow>
                  {wrapInput(
                    'hostEmail',
                    Input,
                    {
                      name: 'hostEmail',
                      placeholder: t('fields.emailsiemens'),
                      ref: register({
                        required: true,
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: '',
                        },
                      }),
                      error: errors['hostEmail'] ? t('fields.required') : '',
                    },
                    1
                  )}
                  <MobileBreak />
                  {wrapInput(
                    'hostFirstname',
                    Input,
                    {
                      name: 'hostFirstname',
                      placeholder: t('fields.firstname'),
                      ref: register({
                        required: true,
                      }),
                      error: errors['hostFirstname']
                        ? t('fields.required')
                        : '',
                    },
                    1
                  )}
                  <MobileBreak />
                  {wrapInput(
                    'hostLastname',
                    Input,
                    {
                      name: 'hostLastname',
                      placeholder: t('fields.lastname'),
                      ref: register({
                        required: true,
                      }),
                      error: errors['hostLastname'] ? t('fields.required') : '',
                    },
                    1
                  )}
                </FormRow>
              </FormField>
              <FormField>
                <Heading type="h5">
                  {t('extrahost.label')}
                  <TooltipIcon value={t('extrahost.copy')} />
                </Heading>
                <>
                  {experts.map((val, i) => (
                    <FormRow key={i}>
                      {wrapInput(
                        'expertEmail',
                        Input,
                        {
                          name: `expertEmail[${i}]`,
                          placeholder: t('fields.emailsiemens'),
                          ref: register({
                            required: false,
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: '',
                            },
                          }),
                          error:
                            errors[`expertEmail`] && errors[`expertEmail`][i]
                              ? t('fields.required')
                              : '',
                        },
                        1
                      )}
                      <MobileBreak />
                      {wrapInput(
                        'expertFirstname',
                        Input,
                        {
                          name: `expertFirstname[${i}]`,
                          placeholder: t('fields.firstname'),
                          ref: register({
                            required:
                              (getValues()?.expertEmail &&
                                getValues()?.expertEmail[i] !== '') ||
                              false,
                          }),
                          error:
                            errors[`expertFirstname`] &&
                            errors[`expertFirstname`][i]
                              ? t('fields.required')
                              : '',
                        },
                        1
                      )}
                      <MobileBreak />
                      {wrapInput(
                        'expertLastname',
                        Input,
                        {
                          name: `expertLastname[${i}]`,
                          placeholder: t('fields.lastname'),
                          ref: register({
                            required:
                              (getValues()?.expertEmail &&
                                getValues()?.expertEmail[i] !== '') ||
                              false,
                          }),
                          error:
                            errors[`expertLastname`] &&
                            errors[`expertLastname`][i]
                              ? t('fields.required')
                              : '',
                        },
                        1
                      )}
                      <MobileBreak />
                      {wrapInput(
                        'time',
                        DateRangePicker,
                        {
                          name: [`expertStart[${i}]`, `expertEnd[${i}]`],
                          label: [t('fields.start'), t('fields.end')],
                          value: startDateHosts[i]
                            ? [startDateHosts[i], endDateHosts[i]]
                            : [startDate, endDate],
                          range: [startDate, endDate],
                          autoSortTimes: true,
                          toggle: true,
                          isSlave: true,
                          tooltip: t('additionalhost.copy'),

                          register,
                          onChange: (time: Dayjs[]) => changeTime(time, i),
                        },
                        'initial'
                      )}
                      {i ? (
                        <RemoveBtn
                          onClick={() => {
                            setExperts(
                              experts
                                .slice(0, i)
                                .concat(experts.slice(i + 1, experts.length))
                            )
                          }}
                        >
                          <SieClose />
                        </RemoveBtn>
                      ) : (
                        ''
                      )}
                    </FormRow>
                  ))}
                  <Button
                    tertiary
                    plain
                    small
                    icon={SiePlus}
                    onClick={() => {
                      setExperts([...experts, [startDate, endDate]])
                    }}
                  >
                    {t('fields.add')}
                  </Button>
                </>
              </FormField>
              <FormField>
                <FormRow>
                  {wrapInput(
                    'note',
                    Textarea,
                    {
                      rows: 4,
                      placeholder: t('fields.note'),
                      ref: register({ required: false }),
                      name: `note`,
                    },
                    1
                  )}
                </FormRow>
              </FormField>
            </SalesFormContent>
            <Button
              type="submit"
              onClick={() => setFormSubmitted(true)}
              disabled={sending || !startDate.isBefore(endDate)}
            >
              {t('fields.submit')}
            </Button>
          </>
        )}
        {props.status === SALESFORM_SENT && (
          <>
            <Heading type="h2">{t('sent.header')}</Heading>
            <Paragraph>{t('sent.copy')}</Paragraph>
            <Button
              icon={SieArrowBack}
              onClick={() => {
                props.onReset && props.onReset()
                setSending(false)
              }}
            >
              {t('sent.back')}
            </Button>
          </>
        )}

        {props.status === SALESFORM_ERROR && (
          <>
            <Heading type="h2">{t('error.header')}</Heading>
            <Paragraph>{props.error && props.error}</Paragraph>
          </>
        )}
        <ReactTooltip effect="solid" className="customTooltip" />
        <ErrorBar
          visible={Object.keys(errors).length > 0 ? `${Math.random()}` : ''}
        >
          {parse(t('errorbar'))}
        </ErrorBar>
      </Form>
    </SalesFormOuter>
  )
}

const SalesFormOuter = styled.div(
  ({ theme: { colors } }) => `
  margin-top:60px;
  ${mq[3]}{
    margin-top:0px;
  }
`
)

const RemoveBtn = styled.div(
  ({ theme: { colors } }) => `
  position:absolute;
  right:0px;
  top:-22px;
  ${mq[2]}{ right:-30px;
    top:12px;};

  cursor:pointer;
  &:hover{
    svg{ fill:${colors.inputBorderHover}}
  }
`
)

export const wrapInput = (
  name: keyof SalesFormData,
  element:
    | React.FC
    | React.FC<DatePickerProps>
    | React.FC<DateRangePickerProps>,
  // tslint:disable-next-line: no-any
  props: any,
  flex: number | string
) => {
  return (
    <div className="elem" style={{ flex }}>
      {React.createElement(element, { ...props })}
    </div>
  )
}

const SalesFormContent = styled.div(({ theme: { grid } }) => ({
  padding: '60px 0',
}))
const FormField = styled.div(({ theme: { grid } }) => ({
  marginBottom: '60px',
}))

const MobileBreak = styled.div(
  ({ theme: { grid } }) => `
  flex-basis: 100%;
    height: 20px;
  ${mq[2]}{
    flex-basis: 0;
    height:0;
  }
`
)

export const FormRow = styled.div(({ theme: { grid } }) => ({
  position: 'relative',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'stretch',
  alignItems: 'center',
  marginBottom: '30px',
  input: { width: '100%' },
  '.elem': {
    [mq[2]]: {
      margin: '0 5px',
    },
    '&:first-of-type': { marginLeft: 0 },
    '&:last-children': { marginRight: 0 },
  },
  button: { marginLeft: '10px' },
}))
