import { css } from '@emotion/css'
import 'react-datepicker/dist/react-datepicker.css'

export const pickerUI = ({ colors, fonts }) => css`
  .react-datepicker-wrapper {
    width: 100%;
    height: 100%;
  }
  .react-datepicker-popper {
    pointer-events: all;
  }
  .react-datepicker {
    user-select: none;
    font-family: ${fonts.fontFamily};
    border-radius: 0;
    border: 1px solid ${colors.inputBorder};
    background: ${colors.inputBackground};
    .react-datepicker__header,
    .react-datepicker__time {
      background: ${colors.inputBackground};
    }
    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-list-item {
      color: ${colors.inputText};
    }
    .react-datepicker__time-list-item--disabled {
      display: none;
    }
    .react-datepicker__month-container,
    .react-datepicker__time {
      background: ${colors.bodyBackground};
    }
    .react-datepicker__day:hover {
      background: ${colors.inputBackground};
    }
    .react-datepicker__day--selected,
    .react-datepicker__time-list-item:hover {
      background: ${colors.inputBorderHover} !important;
      color: black;
    }
    .react-datepicker__day--disabled {
      opacity: 0.2;
      &:hover,
      &:focus {
        border: none;
        outline: 0;
        background: none;
      }
    }
  }
`
