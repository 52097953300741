import { Dayjs, TimeUtil } from 'cuenect-web-core'
import { DateRange } from '../../../../config'

export const flattenDateRange = (
  dRanges: DateRange[]
): {
  dates: Dayjs[]
  firstPossibleDate: Dayjs | null
} => {
  const dateAdd: Dayjs[] = []
  let fPossibleDate: Dayjs | null = null

  dRanges.forEach(([start, end]) => {
    // current date
    let cd: Dayjs = start

    // when current date is before the end date of the given range...
    while (cd.isBefore(end)) {
      // don't include dates from the past
      if (
        cd.isSame(TimeUtil.getNowUtc(), 'day') ||
        cd.isAfter(TimeUtil.getNowUtc(), 'day')
      ) {
        // if there is no first possible date yet, the first found date will be its
        if (!fPossibleDate) {
          fPossibleDate = cd
        }

        // add the date
        dateAdd.push(cd)
      }

      // add 1 day and continue
      cd = cd.add(1, 'day')
    }
  })

  return { dates: dateAdd, firstPossibleDate: fPossibleDate }
}
